import React from "react"
import Header from "../fheader"
import Footer from "../ffooter"
import { Location } from "@reach/router";
import SEO from "../seo";
import styles from "./styles.module.css"
import Helmet from "react-helmet"

export const isBrowser = () => typeof window !== "undefined"

class FLayout extends React.Component{

  constructor(props)
  {
    super(props);
    this.state = {
        classList: ""
    }
  }

  componentDidMount() {
    const { showSingleFooter=false, headerFixed=true, noSelBackground=false } = this.props;
    if(showSingleFooter && !noSelBackground) {
      document.body.classList.add('sel-background');
    }

    if (headerFixed) {
      window.addEventListener('scroll', this.listenToScroll)
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('sel-background'); 
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    
    const scrolled = winScroll 
  
    if(scrolled > 5)
    {
      if(this.state.classList !== "shadow-sm")
        this.setState({
            classList: "shadow-sm"
        })
    }
    else{
      if(this.state.classList === "shadow-sm")
        this.setState({
          classList: ""
        })
    } 
  }

    render(){
      const {showHeaderFooter=true, darkHeader=false, showFooter=true, showSingleFooter=false, onlyLogo=false, headerFixed=true, seoFields={}, showAnnouncement = true, loadTimesThankYouPixel = false, loadTimesLandingPixel = false, loadAdgebraThankYouPixel = false, isFullPage = false, showB2XTypeSelection = false, isActiveB2XTypeInvestors = true, isSignUpPage = false } = this.props;
      var showAnnouncementFinal = showAnnouncement && true;

      return(
        <Location>
          {({ location }) => (
            <>
            <SEO {...seoFields}/>
            <Helmet>
              <script defer>
                {
                  `window.fwSettings={
                      'widget_id':82000000209
                    };
                    !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() `
                }
              </script>
              {
                loadTimesThankYouPixel &&
                <script type="text/javascript">
                  {
                    `
                    function pbTQpixel(e){function t(e,t,c){var n="",r="";try{""!=(n=function(e,t){try{var c={};t.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(e,t,n){c[t]=n});return c.hasOwnProperty(e)?c[e]:""}catch(e){return""}}(e,c))?function(e,t,c){try{var n,r;c?((n=new Date).setTime(n.getTime()+864e5),r="; expires="+n.toGMTString()):r="",document.cookie=e+"="+t+r+";Path=/"}catch(e){}}(t,n,1):n=function(e){try{var t=document.cookie.match(new RegExp("(^| )"+e+"=([^;]+)"));if(t)return t[2]}catch(e){}return null}(t),r=""!=n&&null!=n?"&"+e+"="+n:"&"+e+"="}catch(e){}return r}var c="",n="",r="";try{n=t("ad","acf",c=window.location.href),r=t("col_ci","col_ci",c)}catch(e){console.log(e)}var a="https://ade.clmbtech.com/cde/eventTracking.htm?pixelId="+e+"&_w=1"+n+r+"&rd="+(new Date).getTime();(new Image).src=a};
                    pbTQpixel(8681);
                    `
                  }
                </script>
              }
              {
                loadTimesLandingPixel &&
                <script type="text/javascript">
                  {
                    `
                    function pbLPpixel(e){function t(e,t,c){var n="",r="";try{""!=(n=function(e,t){try{var c={};t.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(e,t,n){c[t]=n});return c.hasOwnProperty(e)?c[e]:""}catch(e){return""}}(e,c))?function(e,t,c){try{var n,r;c?((n=new Date).setTime(n.getTime()+864e5),r="; expires="+n.toGMTString()):r="",document.cookie=e+"="+t+r+";Path=/"}catch(e){}}(t,n,1):n=function(e){try{var t=document.cookie.match(new RegExp("(^| )"+e+"=([^;]+)"));if(t)return t[2]}catch(e){}return null}(t),r=""!=n&&null!=n?"&"+e+"="+n:"&"+e+"="}catch(e){}return r}var c="",n="",r="";try{n=t("ad","acf",c=window.location.href),r=t("col_ci","col_ci",c)}catch(e){console.log(e)}var a="https://ade.clmbtech.com/cde/eventTracking.htm?pixelId="+e+"&_w=1"+n+r+"&rd="+(new Date).getTime();(new Image).src=a};
                    pbLPpixel(12239);
                    `
                  }
                </script>
              }
              {
                loadAdgebraThankYouPixel &&
                <script type="text/javascript">
                  {
                    `
                    function adgebraPixel(){var p1="1882";var p2="[order_Id]";var p3="[product_Id]";var p4="[cartvalue]";var p5="[flag~custom_values]";var adgConvScrpt=document.createElement("script");document.head.appendChild(adgConvScrpt);adgConvScrpt.async=true;var adgConvParam ="p1="+p1+"&p2="+p2+"&p3="+p3+"&p4="+p4+"&p5="+p5;adgConvScrpt.id="adg_conversion_pixel";adgConvScrpt.src="https://adgebra.co.in/Tracker/Conversion.js?"+adgConvParam;};
                    adgebraPixel();
                    `
                  }
                </script>

                
              }
              <script type='text/javascript' src='https://ind-widget.freshworks.com/widgets/82000000209.js' async defer></script>
            </Helmet>
            { showHeaderFooter && <Header darkHeader={darkHeader} location={location} onlyLogo={onlyLogo} fixedClass={this.state.classList} headerFixed={headerFixed} loadTimesThankYouPixel={loadTimesThankYouPixel} loadTimesLandingPixel={loadTimesLandingPixel} loadAdgebraThankYouPixel={loadAdgebraThankYouPixel} showAnnouncement={showAnnouncementFinal} showB2XTypeSelection={showB2XTypeSelection} isActiveB2XTypeInvestors={isActiveB2XTypeInvestors} isSignUpPage={isSignUpPage}/> }
              <div className={[showAnnouncementFinal ? 'show-announcement' : '', showAnnouncementFinal && headerFixed ? styles.showAnnouncement : '', isFullPage ? 'd-flex flex-column flex-grow-1' : ''].join(' ')}>
                {/* {React.cloneElement(this.props.children, {showAnnouncement: showAnnouncementFinal})} */}
                {this.props.children}
              </div>
            { showHeaderFooter && showFooter && !showSingleFooter && <Footer /> }
            {
              showSingleFooter && <div className="container">
                <div className={["row pl-3 pr-3 pl-md-0 pr-md-0"].join(" ")}>
                <div className="f-bottom w-100">
                  <div className="col-md-12 p-md-0">
                    <div className="copyright-text "><p>MProfit Software Private Limited © 2024</p></div>
                  </div>
                  </div>
                </div>
            </div>
            }
            </>
          )}
        </Location>
      )  
    }
}

export default FLayout
