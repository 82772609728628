import { Link, navigate } from "gatsby"
import React from "react"
import  logo  from "../../images/logo.svg"
import  wlogo  from "../../images/wlogo.svg"
import investorsIcon  from "../../images/investorsIcon.svg";
import investorsIconInactive  from "../../images/investorsIconInactive.svg";
import wealthIcon  from "../../images/wealthIcon.svg";
import wealthIconInactive  from "../../images/wealthIconInactive.svg";
import Helmet from "react-helmet"
import styles from "./index.module.css"
import { getUser, logout } from "../../services/auth";
import {Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Tappable from 'react-tappable/lib/Tappable';
import ProceedButton from '../proceedButton';
import { loginToMProfitCloudWithAffiliateLinkIfExists } from "../../common/gFunctions";

export const isBrowser = () => typeof window !== "undefined"

class FHeader extends React.Component {
  constructor(props) {
    super(props);
    this.toggleClass= this.toggleClass.bind(this);
    const user = getUser();
    this.state = {
      user,
      active: false,
      MenuOpen: false,
      dropdownOpen: false,
      dropdownLoginOpen: false,
      mobileContactMenuOpen: false,
      mobileLoginMenuOpen: false
    }
  }

  saveSearchParameters() {
    if (this.props.location.search != '' && this.props.location.search != null && this.props.location.key == 'initial') {
      window.sessionStorage.setItem("urlSearchParams", this.props.location.search);
      window.localStorage.setItem("urlSearchParams", this.props.location.search);
      window.localStorage.setItem("urlSearchParamsTimeStamp", new Date().toISOString());
    }
  }

  toggleClass() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
};


  componentDidMount() {
    window.addEventListener("resize", this.resizeListener);

    this.saveSearchParameters();
  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.resizeListener);
      document.body.classList.remove('modal-open');
      document.body.classList.remove('mobile-menu-open');
  }

  resizeListener = () => {
    if (window.innerWidth > 991) {
      this.closeAllMenus();
    }
  }

  toggle=()=> {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  toggleLogin=()=> {
    this.setState(prevState => ({
      dropdownLoginOpen: !prevState.dropdownLoginOpen
    }));
  }

  navigateToLogin = () => {
    navigate('/login');
  }
  
  toggleMenu = (type='MenuOpen') => {
    const anyMenuOpen = this.state.MenuOpen || this.state.mobileContactMenuOpen || this.state.mobileLoginMenuOpen;
    if (anyMenuOpen) {
      this.closeAllMenus();
    } else {
      this.setState(prevState => ({
        [type]: !prevState[type]
      }),() =>{
      if(this.state[type]) {
       document.body.classList.add('modal-open');
       document.body.classList.add('mobile-menu-open');
      }
      else {
        document.body.classList.remove('modal-open');
        document.body.classList.remove('mobile-menu-open');
      }
     })
    }
  }



  closeAllMenus = () => {
    this.setState({MenuOpen: false,mobileLoginMenuOpen:false,mobileContactMenuOpen:false});
    document.body.classList.remove('modal-open');
    document.body.classList.remove('mobile-menu-open');
  }

  resetUser = () => {
    const user = getUser();
    this.setState({user});
  }

  openFreshworksWidget = () => {
    try {
        window.FreshworksWidget('open');
    } catch {
        
    }
  }

  goToPage = (e, slug, externalUrl, operation, newTab=false, isLoginToMProfitCloud=false) => {
    if (e && (e.ctrlKey || e.metaKey)) {

    } else {
      if (isLoginToMProfitCloud) {
        loginToMProfitCloudWithAffiliateLinkIfExists();
      } else if (slug) {
        navigate(slug);
        if (this.props.location.pathname == slug) {
          this.closeAllMenus();
        }
      } else if (externalUrl) {
        if (!newTab) {
          window.location.href = externalUrl;
        } else {
          window.open(externalUrl,"_blank");
        }
      } else if (operation) {
        switch(operation) {
          case 'logout':
            const isAccountPage = this.props.location.pathname.startsWith('/account');
            isAccountPage ? logout(this.navigateToLogin) : logout(this.resetUser);
            break;
          case 'freshworkswidget':
            this.openFreshworksWidget();
            break;
          default:
            break;
        }
      }
    }
  }

  goToContactUsPage = () => {
    navigate('/contact-us')
  }

  loginToMProfitCloud = () => {
    loginToMProfitCloudWithAffiliateLinkIfExists();
  }

  // pbTQpixel(e){function t(e,t,c){var n="",r="";try{""!=(n=function(e,t){try{var c={};t.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(e,t,n){c[t]=n});return c.hasOwnProperty(e)?c[e]:""}catch(e){return""}}(e,c))?function(e,t,c){try{var n,r;c?((n=new Date).setTime(n.getTime()+864e5),r="; expires="+n.toGMTString()):r="",document.cookie=e+"="+t+r+";Path=/"}catch(e){}}(t,n,1):n=function(e){try{var t=document.cookie.match(new RegExp("(^| )"+e+"=([^;]+)"));if(t)return t[2]}catch(e){}return null}(t),r=""!=n&&null!=n?"&"+e+"="+n:"&"+e+"="}catch(e){}return r}var c="",n="",r="";try{n=t("ad","acf",c=window.location.href),r=t("col_ci","col_ci",c)}catch(e){console.log(e)}var a="https://ade.clmbtech.com/cde/eventTracking.htm?pixelId="+e+"&_w=1"+n+r+"&rd="+(new Date).getTime();(new Image).src=a};

  // pbLPpixel(e){function t(e,t,c){var n="",r="";try{""!=(n=function(e,t){try{var c={};t.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(e,t,n){c[t]=n});return c.hasOwnProperty(e)?c[e]:""}catch(e){return""}}(e,c))?function(e,t,c){try{var n,r;c?((n=new Date).setTime(n.getTime()+864e5),r="; expires="+n.toGMTString()):r="",document.cookie=e+"="+t+r+";Path=/"}catch(e){}}(t,n,1):n=function(e){try{var t=document.cookie.match(new RegExp("(^| )"+e+"=([^;]+)"));if(t)return t[2]}catch(e){}return null}(t),r=""!=n&&null!=n?"&"+e+"="+n:"&"+e+"="}catch(e){}return r}var c="",n="",r="";try{n=t("ad","acf",c=window.location.href),r=t("col_ci","col_ci",c)}catch(e){console.log(e)}var a="https://ade.clmbtech.com/cde/eventTracking.htm?pixelId="+e+"&_w=1"+n+r+"&rd="+(new Date).getTime();(new Image).src=a};

  render() {
    const { darkHeader = false, onlyLogo=false, fixedClass="", location, headerFixed=true, showAnnouncement, loadTimesThankYouPixel = false, loadTimesLandingPixel = false, loadAdgebraThankYouPixel = false, loadAdgebraLandingPixel = false, showB2XTypeSelection = false, isActiveB2XTypeInvestors = true, isSignUpPage = false } = this.props;

    const notLoggedIn = !this.state.user;
    const isAccountPage = location.pathname.startsWith('/account');
    const isOrderPage = location.pathname.startsWith('/order');
    const fixedHeader = !(isAccountPage || isOrderPage) && headerFixed;
    const anyMenuOpen = this.state.MenuOpen || this.state.mobileContactMenuOpen || this.state.mobileLoginMenuOpen;

    return (
        <>
        <header className={ darkHeader ? [styles.topSection, fixedClass ? styles.main_header : styles.navbarDark , fixedHeader ? "fixed-top" : styles.darkFixedSection, fixedClass, anyMenuOpen ?  styles.show : " "].join(" ") : [ fixedHeader ? "fixed-top" : "", fixedClass, styles.main_header].join(" ")}>
          {/* {
            showAnnouncement &&
            <div className={[styles.announcementContainer,'d-flex flex-row align-items-stretch'].join(' ')}>
              <div className={[styles.announcementRow,'d-flex flex-row align-items-center justify-content-center container'].join(' ')}>
                <span className={[styles.announcement,'flex-grow'].join(' ')}>Try the new MProfit for web & mobile</span>
                <div className={styles.proceedButtonContainer}>
                  <ProceedButton buttonText={'Sign up'} key={'announcement-form'} borderRadius={4} onClick={(e)=>{this.goToPage(e,'/sign-up')}} disabled={false} btnExtraClasses={styles.proceedButton}></ProceedButton>
                </div>
              </div>
            </div>
          } */}
          {
            showAnnouncement &&
            <div className={[styles.announcementContainer,'d-flex flex-row align-items-stretch'].join(' ')}>
              <div className={[styles.announcementRow,'d-flex flex-row align-items-center justify-content-center container'].join(' ')}>
                🎉
                <a className={[styles.announcement,'flex-grow'].join(' ')} href="https://www.livemint.com/companies/start-ups/mprofit-raises-2-mn-from-zerodha-s-rainmatter-enam-others-11674045972865.html" target="_blank">We have raised $2mn from Zerodha's Rainmatter, Enam and others. Read more →</a>
              </div>
            </div>
          }
          <div className={["container-fluid", showAnnouncement && styles.showAnnouncement].join(" ")}>
            <nav className={["navbar", styles.mainnav, "navbar-expand-lg"].join(" ")}>
              <Link className={["navbar-brand", "main-logo",styles.navbarBrand].join(" ")} to="/"><img className="w-100" src={ darkHeader && !anyMenuOpen && !fixedClass ? wlogo : logo} alt="" /></Link>
              {
                !onlyLogo &&
                <>
                {
                  !anyMenuOpen &&
                  <div className={[styles.header_bottons,"d-lg-none",'ml-auto','mr-3'].join(' ')}>
                    <Tappable onTap={(e)=>this.toggleMenu('mobileLoginMenuOpen')} onClick={() => this.toggleMenu('mobileLoginMenuOpen')}>
                      <button className="ml-2 mr-2 pl-2 pr-2"><i className="fa fa-lg fa-user" aria-hidden="true"></i></button>
                    </Tappable>
                    <Tappable onTap={(e)=>this.toggleMenu('mobileContactMenuOpen')}>
                      <button className="ml-2 mr-2 pl-2 pr-2"><i className="fa fa-lg fa-phone" aria-hidden="true"></i></button>
                    </Tappable>
                  </div>
                }
                <Tappable onTap={(e)=>this.toggleMenu()} className={'tappable-hitslop'}>
                <button className={["navbar-toggler", styles.toggle_icon, anyMenuOpen ? styles.show : " "].join(" ")} type="button" >
                    <span></span>
                </button>
                </Tappable>
                {
                  this.state.mobileLoginMenuOpen &&
                  <div className={["navbar-collapse", styles.navbarCollapse, styles.show,"d-lg-none"].join(" ")}>
                    <MobileMenu list={notLoggedIn ? notLoggedInList : loggedInList} isOpen={this.state.mobileLoginMenuOpen} goToPage={this.goToPage} onlyShowOnMobile={true}/>
                  </div>
                }
                {
                  this.state.mobileContactMenuOpen &&
                  <div className={["navbar-collapse", styles.navbarCollapse, styles.show,"d-lg-none"].join(" ")}>
                    <MobileMenu list={contactUsList} isOpen={this.state.mobileContactMenuOpen} goToPage={this.goToPage} onlyShowOnMobile={true}/>
                  </div>
                }
                {
                  this.state.MenuOpen &&
                  <div className={["navbar-collapse", styles.navbarCollapse, styles.show,"d-lg-none"].join(" ")}>
                    <MobileMenu list={navListMobile} isOpen={this.state.MenuOpen} goToPage={this.goToPage} onlyShowOnMobile={true}/>
                    <ul className={[styles.navbarNav,styles.navbarTopBorder,styles.mlAuto,"navbar-nav", "ml-auto","d-lg-none","d-block"].join(" ")}>
                      <li>
                        <span className={["dropdown-toggle", styles.dropdownToggle, styles.mobileDropdownTitle].join(" ")}>Contact Us </span>
                        {/* <UncontrolledCollapse toggler="#toggler"> */}
                          <div className={["bg-light pt-3 pb-3 ml-2 mr-2", styles.mobileMenuDropdown].join(" ")}>
                            <span className="dropdown-item" onClick={this.openFreshworksWidget}>Request a call-back</span>
                            <a className="dropdown-item" href="mailto:MProfit Support<support@mprofit.in>">Email us at support@mprofit.in</a>
                          </div>
                        {/* </UncontrolledCollapse> */}
                      </li>
                      {
                        notLoggedIn ?
                        <li>
                            <span className={["dropdown-toggle", styles.dropdownToggle, styles.mobileDropdownTitle].join(" ")} >Login </span>
                            {/* <UncontrolledCollapse toggler="#toggler_1"> */}
                              <div className={["bg-light pt-3 pb-3 ml-2 mr-2", styles.mobileMenuDropdown].join(" ")}>
                                <a className="dropdown-item" onClick={this.loginToMProfitCloud}>Login to MProfit Cloud</a>
                                <Link className="dropdown-item" to='/login'>My Account</Link>
                              </div>
                            {/* </UncontrolledCollapse> */}
                        </li>
                        :
                        <li>
                          <span className={["dropdown-toggle", styles.dropdownToggle, styles.mobileDropdownTitle].join(" ")} >{this.state.user.userName} </span>
                          {/* <UncontrolledCollapse toggler="#toggler_2"> */}
                            <div className={["bg-light pt-3 pb-3 ml-2 mr-2", styles.mobileMenuDropdown].join(" ")}>
                                <Link className="dropdown-item" to='/account/plan'>My Account</Link>
                                <Link className="dropdown-item" to="/account/order/product">Renew / Upgrade</Link>
                                <a className="dropdown-item" onClick={this.loginToMProfitCloud}>Login to MProfit Cloud</a>
                                <a className="dropdown-item" onClick={()=>{ isAccountPage ? logout(this.navigateToLogin) : logout(this.resetUser)}}>Sign out</a>
                            </div>
                          {/* </UncontrolledCollapse> */}
                        </li>
                      }
                    </ul>
                  </div>
                }
                <div className={["navbar-collapse", styles.navbarCollapse].join(" ")}>
                  {
                    showB2XTypeSelection || isSignUpPage
                    ?
                    <>
                    {
                    showB2XTypeSelection ?
                    <div className={styles.b2xTypeSelectionContainer}>
                      <Link className={styles.b2xTypeContainer} to={'../investors'}>
                        <img src={isActiveB2XTypeInvestors ? investorsIcon : investorsIconInactive}/>
                        <span className={[styles.b2xType,isActiveB2XTypeInvestors ? '' : styles.b2xTypeInactive].join(' ')}>Investors</span>
                      </Link>
                      <Link className={styles.b2xTypeContainer} to={'../wealth'}>
                        <img src={isActiveB2XTypeInvestors ? wealthIconInactive : wealthIcon}/>
                        <span className={[styles.b2xType,!isActiveB2XTypeInvestors ? '' : styles.b2xTypeInactive].join(' ')}>Wealth Professionals</span>
                      </Link>
                    </div>
                    :
                    <div className={styles.b2xTypeSelectionContainer}>
                      
                    </div>
                    }
                    <form className={[styles.b2xPageLoginBtnContainer].join(" ")}>
                      <span className={styles.alreadyHaveAccount}>Already have an account?</span>
                      <button className={["btn", styles.navbtn].join(" ")} type="button" onClick={()=>{navigate('/login')}}>Login</button>
                    </form>
                    </>
                    :
                    <>
                    <MobileMenu list={notLoggedIn ? navListNotLoggedIn : navListLoggedIn} isOpen={this.state.MenuOpen} goToPage={this.goToPage}/>
                    <ul  className={[styles.navbarNav,styles.mlAuto,"navbar-nav", "ml-auto","d-none","d-md-flex"].join(" ")}>
                    <Dropdown tag="li" className="nav-item dropdown has-down" isOpen={this.state.dropdownOpen} toggle={()=>this.toggle}  onClick={()=>this.toggle}>
                      <DropdownToggle  tag="a" className={["dropdown-toggle", styles.dropdownToggle].join(" ")} onClick={this.goToContactUsPage}>
                        Contact Us
                      </DropdownToggle>
                      <DropdownMenu right={true}>
                        {
                          contactUsList.map((item,index) => (
                            <span key={index} className="dropdown-item" onClick={(e)=>this.goToPage(e, item.slug, item.externalUrl, item.operation, item.newTab)}>{getItemText(item)}</span>
                          ))
                        }
                      </DropdownMenu>
                    </Dropdown>
                      {
                        notLoggedIn ?
                        <Dropdown tag="li" className="nav-item dropdown" isOpen={this.state.dropdownLoginOpen} toggle={this.toggleLogin} onClick={()=>this.togglelogin}>
                          <DropdownToggle  tag="a" className={["dropdown-toggle", styles.dropdownToggle].join(" ")} >
                            Login
                          </DropdownToggle>
                          <DropdownMenu right={true}>
                          <a className="dropdown-item" onClick={this.loginToMProfitCloud}>Login to MProfit Cloud</a>
                            <Link className="dropdown-item" to='/login'>My Account</Link>
                          </DropdownMenu>
                        </Dropdown>
                        :
                        <Dropdown tag="li" className="nav-item dropdown" isOpen={this.state.dropdownLoginOpen} toggle={this.toggleLogin}>
                          <DropdownToggle  tag="a" className={["dropdown-toggle", styles.dropdownToggle].join(" ")} >
                            {this.state.user.userName}
                          </DropdownToggle>
                          <DropdownMenu right={true}>
                          <Link className="dropdown-item" to='/account/plan'>My Account</Link>
                              <Link className="dropdown-item" to="/account/order/product">Renew / Upgrade</Link>
                              <a className="dropdown-item" onClick={this.loginToMProfitCloud}>Login to MProfit Cloud</a>
                              <a className="dropdown-item" onClick={()=>{ isAccountPage ? logout(this.navigateToLogin) : logout(this.resetUser)}}>Sign out</a>
                          </DropdownMenu>
                        </Dropdown>
                      }
          
                    </ul>
                    
                    {
                      notLoggedIn &&
                      <form className={[styles.formlnline,"form-inline"].join(" ")}>
                        <button className={["btn", styles.navbtn].join(" ")} type="button" onClick={()=>{navigate('/sign-up')}}>Sign up</button>
                      </form>
                    }
                    </>
                  }
                </div>
                </>
              }
            </nav>
          </div>
      </header>
      </>
    )
  }
}

export default FHeader

const navListLoggedIn = [
  {slug: '/features', title: 'Features'},
  {slug: '/pricing', title: 'Pricing'},
  // {slug: '/sign-up', title: 'Sign up'},
  // {slug: '/clients', title: 'Clients'},
  {slug: '/import', title: 'Import'},
  {slug: '/about', title: 'About'},
  // {slug: '/reviews', title: 'Reviews'},
]

const navListNotLoggedIn = [
  ...navListLoggedIn,
  {slug: '/buy', title: 'Subscribe', isBuyButtonStyling: true, hideIfLoggedIn: true}
]

const navListMobile = [
  {slug: '/features', title: 'Features'},
  {slug: '/pricing', title: 'Pricing'},
  {slug: '/sign-up', title: 'Sign up'},
  // {slug: '/clients', title: 'Clients'},
  {slug: '/reviews', title: 'Reviews'},
  {slug: '/import', title: 'Import'},
  {slug: '/blog', title: 'Blog'},
  {slug: '/about', title: 'About'},
  {slug: '/buy', title: 'Subscribe'},
]

const contactUsList = [
  {externalUrl: 'https://help.mprofit.in', title: 'MProfit Help Center', newTab: true},
  {operation: 'freshworkswidget', title: 'Request a call-back'},
  {externalUrl: 'mailto:MProfit Support<support@mprofit.in>', title: 'Email us at ', highlightTextEnd: 'support@mprofit.in'},
  {slug: '/blog', title: 'MProfit Blog', newTab: true},
]

const notLoggedInList = [
  {slug: '/buy', title: 'Subscribe'},
  {slug: null, isLoginToMProfitCloud: true, title: 'Login to MProfit Cloud'},
  {slug: '/login', title: 'My Account'},
]

const loggedInList = [
  {slug: '/account/plan', title: 'My Plan'},
  {slug: '/account/users', title: 'My Users'},
  {slug: '/account/referral', title: 'Referrals'},
  {slug: '/account/profile', title: 'My Profile'},
  {slug: '/account/order-history', title: 'Order History'},
  {slug: null, title: 'Sign out', operation: 'logout'},
]

const MobileMenu = ({list, isOpen, goToPage, onlyShowOnMobile}) => {
  return (
    // <div className={["navbar-collapse", styles.navbarCollapse , isOpen ?  styles.show : " "].join(" ")}>
    <ul className={[styles.navbarNav,"navbar-nav", "mr-auto",onlyShowOnMobile&&"d-lg-none"].join(" ")}>
      {
        list.map((item,index) => {
          var title = item.title + (item.highlightTextEnd ? ' ' + item.highlightTextEnd : '');
          return (
            <Tappable onTap={(e)=>goToPage(e, item.slug, item.externalUrl, item.operation, item.newTab, item.isLoginToMProfitCloud)} classes={{active:styles.tapItem}} key={index}>
              <li className={[styles.navItem,"nav-item"].join(' ')}>
                  {
                    onlyShowOnMobile ?
                    <span>{title}</span>
                    :
                    getLinkOrSpecialStyling(item, title, item.isBuyButtonStyling)
                    // (
                    // item.slug ?
                    // <Link to={item.slug}>{title}</Link>
                    // :
                    // <a href={item.externalUrl}>{title}</a>
                    // )
                  }
              </li>
            </Tappable>
          )
        })
      }
    </ul>
    // </div>
  )
}

const getLinkOrSpecialStyling = (item, title, isBuyButtonStyling = false) => {
  if (isBuyButtonStyling) {
    return <div className={styles.buyButtonGreen}>{getLinkOrSpecialStyling(item, title)}</div>
  } else if (item.slug) {
    return <Link to={item.slug}>{title}</Link>
  } else {
    return <a href={item.externalUrl}>{title}</a>
  }
}

const getItemText = (item) => {
  return (
    <>
      {item.title}
      {
        item.highlightTextEnd &&
        <span className={styles.highlightedText}>{item.highlightTextEnd}</span>
      }
    </>
  )
}

// const ContactUsMenu = () => {
//   return (
//     <ul className={[styles.navbarNav,"navbar-nav", "mr-auto",onlyShowOnMobile&&"d-lg-none"].join(" ")}>
//             <Tappable onTap={()=>goToPage(item.slug, item.externalUrl)} classes={{active:styles.tapItem}} key={index}>
//               <li className={[styles.navItem,"nav-item"].join(' ')}>
//                   <Link to={item.slug}>{item.title}</Link>
//               </li>
//             </Tappable>

//     </ul>
//   )
// }